a.anchor {
  display: block;
  position: relative;
  top: -125px;
  visibility: hidden;
}


.section-title {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 2em;
  display:block;
}


.presentation-section {
  margin: 4em 5em;
}

.about-container {
  display: flex;
  align-items: center;
  margin-top: 2em;
  justify-content: space-around;
  margin-bottom: 1em;
}

.about-text {
  font-family: "Roboto", sans-serif;
  line-height: 2.3em;
  font-size: 1.3rem;
  max-width: 50ch;
  text-align: justify;
}

.about-text p {
  margin: 2em 0em;
}

.about-img {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  max-width: 45%;
}

.about-img img {
  width: 100%;
  border-radius: 12px;
}



.card-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5em 0em;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: center;
  margin-top:2em;
}

.card {
  border: 1px solid rgba(112, 112, 112, 0.35);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  max-width: 300px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5em;
}

.card-content {
  margin-top: 1em;
}

.card-subtext {
  color: var(--text-green);
  text-align: center;
  padding: 0em 1em;
  font-size: 1.5em;
}



.card .img-container {
  width: 100%;
  border-radius: 11px;
}

.card .img-container img {
  width: 100%;
  border-radius:12px 12px 0px 0px;
}

.card-text {
  padding: 1em;
}
.brands-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 2em 0em 1em 0em;
}

.brands-container .img-container {
  max-height: 200px;
  margin: 0em 1em;
}

.brands-container .img-container img {
  max-height: 200px;
}

.call-to-action {
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  padding: 2em 0em;
  margin-top: 3em;
}

.cta-btn {
  text-decoration: none;
  color: var(--text-green);
  padding: 0.5em 2em;
  background-color: #ffffff;
  text-align: center;
  margin-bottom: 3em;
  border-radius: 5px;
  box-shadow: 2px 3px 10px 0px #0000003b;
  font-weight: 600;
  margin-top: 3em;
  cursor: pointer;
  font-size:1.5em;
}

.cta-btn-hero {
  text-decoration: none;
  color: var(--text-green);
  padding: 0.5em 2em;
  background-color: #ffffff;
  text-align: center;
  margin-bottom: 3em;
  border-radius: 5px;
  box-shadow: 2px 3px 10px 0px #0000003b;
  font-weight: 600;
  margin-top: 3em;
  cursor: pointer;
}

.cta-btn-partnership {
  text-decoration: none;
  color: #ffffff;
  padding: 0.5em 3.5em;
  background-color: var(--primary-green);
  text-align: center;
  margin-bottom: 3em;
  border-radius: 5px;
  box-shadow: 2px 3px 10px 0px #0000003b;
  font-weight: 600;
  margin-top: 3em;
  font-size:1.5em;
}

.cta-title{
  font-size: 2em;
}

.cta-sub-text {
  margin-bottom: 1em;
  font-size:1.5em;
  
}

.cta-text{
  font-size:1.2em;
}

.cta-content {
  max-width: 50%;
  margin-top: 2em;
}

.support-section {
  margin: 2em 4em;
}

.newsletter {
  align-self: flex-end;
  margin-right: 5em;
  font-family: "Roboto", sans-serif;
}

.newsletter-email {
  border: none;
  padding: 1em 5em;
  text-align: center;
  background: #f4f4f4f4;
  border-radius: 5px;
  font-size: 0.9em;
  box-shadow: 2px 3px 5px 0px #0000003b;
  color: black;
}

.newsletter-submit {
  margin-top: 1em;
  border: none;
  background-color: var(--primary-green);
  padding: 1em;
  color: #ffffff;
  font-size: 0.9em;
  font-weight: 600;
  box-shadow: 2px 3px 5px 0px #0000003b;
  border-radius: 5px;
  cursor:pointer;
}

.newsletter > form {
  padding: 1em;
  background-color: #ffffff;
  flex-direction: column;
  display: flex;
  border-radius: 10px 10px 0px 0px;
}

@media (max-width: 80em) {
  .about-container {
    display: flex;
    align-items: center;
    margin-top: 1em;
    justify-content: space-around;
    flex-direction: column;
  }

  .about-text {
    font-family: "Roboto", sans-serif;
    line-height: 1.5em;
    font-size: 1.2em;
    max-width: 45%;
  }
  
  .about-text p {
    margin: 2em 0em;
  }
  .about-img {
    max-width: 90%;
    order: 1;
  }

  .about-text {
    max-width: 90%;
  }
  .presentation-section {
    margin: 0em 12em 5em 12em;
  }
}

@media (max-width: 57em) {
  .presentation-section {
    margin: 2em 1em 6em ;
  }
  .brands-container {
    flex-direction: column;
    justify-content: space-between !important;
    align-items: space-between;
  }

  .card-section{
    margin-bottom:6em;
  }

  .brands-container .img-container {
    margin-bottom: 1em;
  }

  .section-deals {
    margin-bottom: 1em;
  }
  .newsletter {
    align-self: center;
    margin: 2em 0em 0em 0em;
  }

  .cta-title {
    text-align: center;
    margin: 0em 1em;
  }
}
