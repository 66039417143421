body {
    height: 100vh;
    display:flex;
    flex-direction:column;
  }
  
  .partnership-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif;
    margin: 9em 5em 5em 5em;
  }
  
  .partnership-form {
    background-color: var(--grey-color);
    padding: 2em;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.18);
    border-radius: 13px;
    border: 1px solid rgba(112, 112, 112, 0.35);
  }
  
  .partnership-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-title {
    color: var(--text-green);
    font-size: 2em;
    text-align: center;
    margin-bottom: 0.5em;
  }
  
  .form-sub-text {
    text-align: center;
    margin-bottom: 3em;
  }
  
  .field {
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(112, 112, 112, 0.35);
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 1em;
    margin-bottom: 1em;
    padding: 1em;
    width: 90%;
  }
  
  .submit-btn {
    text-decoration: none;
    color: #ffffff;
    background-color: var(--primary-green);
    text-align: center;
    margin-bottom: 3em;
    border-radius: 5px;
    box-shadow: 2px 3px 10px 0px #0000003b;
    font-weight: 600;
    margin-top: 3em;
    width: 50%;
    padding: 1em;
    font-size: 1em;
    cursor:pointer;
  }

  .submit-btn:hover{
    color: var(--primary-green) !important;
    background-color: #F4F4F4!important;
}
  
  @media(max-width:57em){
  .partnership-form{
    padding:0.5em;
  }
  
  .partnership-content{
    margin:12em 1em 1em 1em;
  }
   }
  
  
  