.logo {
  max-width: 45%;
}

.hero-container {
  height: 100vh;
}

.hero {
  background: linear-gradient(
    180.01deg,
    #14c570 1.32%,
    #808080 250.56%,
    #000000 250.56%
  );
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 3px 0px #00000033;
  z-index: 3;
  position: relative;
}

.hero-nav-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 85%;
  margin-top: 1em;
}

.hero-nav-links > a {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.25em;
  text-decoration: none;
}

.hero-sub-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  font-style: italic;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  font-size: 2em;
  margin: 0em 1em;
}

.hero-btn {
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  color: #323232;
  padding: 0.5em 2em;
  background-color: #ffffff;
  text-align: center;
  margin-bottom: 1em;
  border-radius: 5px;
  box-shadow: 2px 3px 10px 0px #0000003b;
  cursor:pointer;
}

.dropdown-arrow {
  height: 1em;
  margin-bottom: 1em;
}

.icon-layout {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 15vh;
}

.icon-container {
  height: 20px;
  cursor: pointer;
}

.icon-container img {
  height: 100%;
}

.icon-container:after {
  content: attr(data-hover);
  visibility: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.26);
  border-radius: 11px;
  color: #000000;
  text-align: center;
  border-radius: 5px;
  padding: 0.5em;
  transition: opacity 1s ease-in-out;
  display:flex;
  position:absolute;
  z-index: 4;
  cursor: pointer;
  top:93%;
  left:50%;
  transform: translate(-50%, 50%);
}

.icon-container:hover:after {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 60em) {
  .logo {
    max-width: 80%;
  }
  .hero-nav-links {
    justify-content: center;
    gap:3.5em;
  }
}
