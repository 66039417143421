.building-section{
    height:78.8vh;
    display:flex;
    justify-content: center;
    align-items: center;
    background:url(../assets/img/excavator.jpg);
    background-size: cover;
background-repeat: no-repeat;

    
}

.building-text{
    font-family: 'Montserrat', sans-serif;
    font-size:1.5em;
    margin-top: 1em;
    
}
.signature{
  font-family: "Roboto", sans-serif;
  font-style: italic;
  text-align: right;
}

.building-section{
    height:90vh;
    display:flex;
    justify-content: center;
    align-items: center;
    
}

.building-text{
    font-family: 'Montserrat', sans-serif;
    font-size:1.5em;
    margin-top: 1em;
    
}
.signature{
  font-family: "Roboto", sans-serif;
  font-style: italic;
  text-align: right;
}

@media (max-width:70em){
    .building-section{
        padding:0em 2em;
    }
    
    .signature{
        text-align: left;
    }
}
