.navbar {
  background-color: var(--primary-green);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1em;
  box-shadow: 0px 4px 3px 0px #00000040;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  
}


.navbar .img-container img {
  max-height: 35px;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  width: 70%;
}

.nav-links > a {
  color: #ffffff;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.5em;
  margin-right:1.5em;
}

.nav-links > a:hover {
  color: #000000f4;
  transition: 1s;
}

.hamburger-button{
  display:none;
  font-size: 2.5em;
  margin-right: 1em;
  cursor:pointer;
  user-select: none;
}



.hamburger-nav{
  display:flex;
  flex-direction: column;
  position:absolute;
  top:5em;
  right:0em;
  background-color: var(--primary-green);
  padding:2em;
  display:none;
  height:100vh;
  width:100%;
  
}

.hamburger-show{
  display:none;
  transition:0.5s;
}


.hamburger-nav a {
  font-size: 1.7em;
  text-decoration: none;
  color:#FFFFFF;
  text-transform: uppercase;
  text-align: right;
  margin-top:1.2em;
  

  

}


@media (max-width: 53em) {

  .hamburger-button{
   display:block;
 } 

  .nav-links a{
    display:none;
  } 

  .hamburger-show{
    display:flex;
  }

  header > nav {
    
    flex-direction:column;
    align-items: flex-end;
    text-align:right;
   
  }

  header > nav > a {
    margin:0.4em 1em;
    font-size:1.2em;
    width: 80%;
  }
}




