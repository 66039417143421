:root {
  --primary-green: #2eb574;
  --text-green: #14c570;
  --dark-color: #2f3a32;
  --grey-color: #f4f4f4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

html {
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,800&display=swap");
