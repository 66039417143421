.footer {
  background-color: var(--primary-green);
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-family: "Roboto", sans-serif;
  padding: 1em 2em;
}

.footer h3,
.footer a {
  color: #ffffff;
  opacity: 70%;
  text-decoration: none;
}

.footer-section {
  display: flex;
  flex-direction: column;
}

.footer-section h3 {
  font-size: 1.5em;
  font-style: italic;
  margin: 0.5em 0em;
  font-family: 'Montserrat', sans-serif;
}

.footer-section a {
  margin-bottom: 0.4em;
}

.copyright{
  align-self: flex-end;
  color:#FFFFFF;
  justify-self:center;
  background-color: var(--primary-green);
  text-align: center;
  padding-bottom: 1em;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 40em) {
  .footer {
    flex-direction: column;
    justify-content: space-between;
  }
}
