


.orders-section {
  margin: 9em 5em 5em 5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orders-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-color);
  padding: 2em;
  box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.18);
  border-radius: 13px;
  border: 1px solid rgba(112, 112, 112, 0.35);
  max-width: 60%;
  gap:1em;
}

.orders-form input,
select,
textarea {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(112, 112, 112, 0.35);
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 1em;
  margin-bottom: 1em;
  padding: 1em;
  width: 90%;
}

.form-title {
  color: var(--text-green);
  font-size: 2em;
  text-align: center;
  margin-bottom: 0.5em;
}

.form-sub-text {
  text-align: center;
  margin-bottom: 3em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.radio-btns {
  display: flex;
  gap: 3em;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1em 0em 3em 0em;
  font-size: 1.2em;
}

.radio-btns-title {
  font-size: 1.2em;
  margin: 1em 0em 0em 0em;
}

textarea {
  resize: vertical;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
}

text-area::placeholder {
  font-size: 1em;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

textarea::-webkit-input-placeholder {
  font-size: 1em;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  font-size: 1em;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 1em;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

textarea:-ms-input-placeholder {
  font-size: 1em;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}



.submit-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: var(--primary-green) !important;
  text-align: center;
  margin-bottom: 3em;
  border-radius: 5px;
  box-shadow: 2px 3px 10px 0px #0000003b;
  font-weight: 600;
  margin-top: 3em;
  width: 50%;
  padding: 1em;
  font-size: 1em;
  cursor:pointer;
  transition:0.5s;
}

.submit-btn:hover{
    color: var(--primary-green) !important;
    background-color: #F4F4F4!important;
}

/* HIDE RADIO */
[type="radio"] {
  
  opacity: 0;
  width: 0;
  height: 0;
  
}

/* IMAGE STYLES */
[type="radio"] + img {
  cursor: pointer;
  transition:0.1s;
}

/* CHECKED STYLES */
[type="radio"]:checked + img {
  outline: 3px dashed #57AF32;
  background-color: #ffffff;
}

.radio-img {
  height: 170px;
  padding:0.3em;
}

span{
  color:rgb(221, 5, 5)
}

@media (max-width: 100em) {
  .orders-form {
    padding: 1em 0.5em;
    max-width: 90%;
  }

  .orders-section {
    margin: 12em 1em 5em 1em;
  }


}
@media (max-width: 60em) {
.radio-img {
    height: 150px;
  }
}

@media (max-width: 55em) {
  .radio-btns {
    display: flex;
    gap: 1em;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.2em;
    margin-top: 1em;
  }

  .radio-img {
    height: 170px;
  }

}
