.container {
  min-height: calc(100vh - 171px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 0em 1em;
}

.content p {
  max-width: 70ch;
  text-align: justify;
}

.paragraph h2 {
  margin-bottom: 0.5em;
}

.paragraph p {
  margin-bottom: 0.3em;
}

@media screen and (max-width: 1256px) {
  .container {
    margin-top: 100px;
  }
}
