
.blog-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    max-width: 60em;
    margin: 5em auto;
  }
  
  .blog-header {
    text-align: center;
    margin: 4em 0em;
  }
  .blog-header h1 {
    margin-bottom: 1em;
    font-family: 'Montserrat', sans-serif;
  }
  
  .blog-header h2,
  h3 {
    opacity: 40%;
  }
  
  article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align:justify;
    line-height: 1.7rem;
    font-size:1.2rem;
  }
  
  article .img-container img {
    width: 100%;
    border-radius: 11px;
  }
  
  .article-title {
    text-align: center;
    margin: 2em 0em;
    font-family: 'Montserrat', sans-serif;
  }
  
  .section-title {
    text-align: center;
    margin: 1em 0em;
    font-family: 'Montserrat', sans-serif;
  }
  
  @media(max-width:65em){
    .blog-content{
      margin:5em 1.5em 4em 1.5em;
    }
    .article{
      margin:0em 5em;
    }
   }