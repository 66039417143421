.terms_agreement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  margin: 7em auto;
}

.terms_agreement h1 {
  margin-bottom: 1em;
}

.terms_agreement h2 {
  margin: 1em 0em;
}

.paragraph p {
  margin-bottom: 0.5em;
}

.paragraph ul {
  list-style-type: disc !important;
  padding-left: 1em;
  list-style-position: inside;
}

.paragraph li {
  margin-bottom: 0.3em;
}
